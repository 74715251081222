button,
input[type='button'],
input[type='submit'],
a.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font);
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  padding: rem(12) rem(40);
  background: c(primary);
  color: c(primary-sc);
  font-size: rem(18);
  border: 1px solid c(primary-hover);
  text-decoration: none;
  white-space: nowrap;
  &.with-icon {
    padding: rem(10) rem(15);
    > span {
      display: inline-grid;
      grid-auto-flow: column;
      gap: 10px;
      align-items: center;
      b {
        font-weight: normal;
        line-height: 1;
      }
    }
    i {
      font-size: rem(26);
    }
  }
  &:hover,
  &:active {
    background: c(primary-hover);
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.gray {
    background: c(dark, 0.4);
    border: 1px solid c(dark, 0.1);
    color: #fff;
    &:hover,
    &:active {
      background: c(dark, 0.45);
    }
  }
  &.red {
    background: c(danger, 0.8);
    border: 1px solid c(dark, 0.1);
    color: #fff;
    &:hover,
    &:active {
      background: c(danger, 0.9);
    }
  }
  &.light {
    border: 1px solid c(dark, 0.05);
    background: c(dark, 0.02);
    color: c(dark);
    &:hover,
    &:active {
      background: c(dark, 0.06);
    }
  }
}
.button-loading {
  position: relative;
  --car: var(--primary);
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.55);
    --light: var(--car);
    --primary: var(--primary-sc);
    width: 120px;
    & ~ button {
      color: transparent;
      pointer-events: none;
      opacity: 1;
      background: var(--car);
    }
  }
}
label {
  font-size: rem(16);
  color: c(dark, 0.8);
  + input,
  + textarea {
    margin: 12px 0 0;
  }
}
.errors-block {
  &:empty {
    display: none;
  }
  .input-message {
    font-size: rem(16);
  }
}
.input-message {
  &:empty {
    display: none;
  }
  & ~ .input-message {
    margin: 5px 0 0;
  }
  &.error {
    color: c(danger);
  }
  &.login {
    padding: 20px 0 0;
    font-size: rem(16);
  }
  color: c(dark, 0.8);
  font-size: 13px;
  text-align: left;
  padding: 0 5px;
}
input[type='text'],
input[type='date'],
input[type='number'],
input[type='password'],
input[type='email'],
input[type='search'],
textarea,
select {
  padding: 0 14px;
  font-size: 15px;
  border-radius: 5px;
  width: 100%;
  max-width: 600px;
  min-height: 48px;
  border: 1px solid c(dark, 0.05);
  background: c(dark, 0.02);
  color: c(dark);
  .login-form & {
    background: c(dark, 0.04);
    border: 1px solid c(dark, 0.08);
  }
  .add-form & {
    max-width: 600px;
  }
}
select {
  cursor: pointer;
  padding: 24px 38px 4px 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  .dark-mode & {
    background: #171717;
  }
}
input[type='text'],
input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'],
input[type='search'],
textarea,
select {
  & ~ .input-message {
    margin: 5px 0 0;
  }
  &::placeholder {
    color: c(dark, 0.4);
    opacity: 1;
  }
  &:focus {
    border-color: c(dark, 0.2);
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: c(dark);
    -webkit-box-shadow: inset 0 0 0 50px c(light);
    border: 1px solid c(primary);
  }
  &.success {
    &,
    &:focus {
      border-color: c(success);
    }
  }
  &.danger {
    &,
    &:focus {
      border-color: c(danger);
    }
  }
  &.warning {
    &,
    &:focus {
      border-color: c(warning);
    }
  }
  &:disabled {
    opacity: 0.75;
  }
  & + p {
    font-size: rem(14);
  }
}
.input-with-label {
  position: relative;
  max-width: 600px;
  min-width: 180px;
  width: 100%;
  > p {
    position: absolute;
    top: -10px;
    right: 10px;
    background: c(light);
    border: 1px solid c(danger);
    border-radius: 4px;
    .input-with-icon & {
      right: 48px;
    }
  }
  > span {
    position: absolute;
    font-size: 15px;
    left: 14px;
    top: 15px;
    pointer-events: none;
    transition: all 200ms ease-in-out;
    color: c(dark, 0.4);
  }
  > input {
    padding: 14px;
    &:disabled + span {
      color: c(dark, 0.4);
    }
    &::placeholder {
      color: transparent;
    }
    &:focus,
    &:not(:placeholder-shown) {
      padding: 23px 14px 5px;
      .input-with-icon & {
        padding: 23px 47px 5px 14px;
      }
      + span {
        font-size: 12px;
        top: 8px;
        color: c(dark, 0.7);
      }
    }
  }
}
.input-with-icon {
  position: relative;
  max-width: 600px;
  .add-form & {
    max-width: 600px;
  }
  &.left {
    > i,
    > button {
      left: 14px;
      right: initial;
    }
    > .input-with-label {
      > span {
        left: 44px;
      }
      > input {
        padding: 23px 14px 5px 44px;
      }
    }
  }
  > i,
  > button {
    position: absolute;
    font-size: 20px;
    right: 14px;
    top: 14px;
    pointer-events: none;
  }
  > input {
    padding: 14px 44px 14px 14px;
    &:disabled + i {
      color: c(dark, 0.4);
    }
  }
}
textarea {
  resize: none;
  min-height: 124px;
  padding: 14px;
}
.radio {
  .checkmark {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    > i {
      &:before {
        content: '' !important;
      }
      top: 2px;
      left: 2px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
  }
}
.checkbox {
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 5px;
    &.intermediate {
      font-size: 9px;
      font-weight: bold;
      > .checkmark {
        visibility: hidden;
      }
    }
    > i {
      font-size: 10px;
      line-height: 18px;
      text-align: center;
      color: c(primary-sc);
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      border-radius: 5px;
    }
  }
  input {
    &:checked ~ .checkmark {
      border: none !important;
    }
  }
}
.radio,
.checkbox {
  display: inline-flex;
  align-items: center;
  position: relative;
  min-width: 18px;
  cursor: pointer;
  font-size: rem(16);
  user-select: none;
  min-height: 18px;
  .text {
    padding: 0 3px 0 26px;
    a {
      text-decoration: underline !important;
      color: c(primary);
      &:hover {
        text-decoration: none !important;
      }
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ .checkmark {
      background: c(light);
      border: 1px solid c(primary);
      > i {
        display: block;
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    background: c(dark, 0.05);
    border: 1px solid c(dark, 0.2);
    > i {
      position: absolute;
      display: none;
      background: c(primary);
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}
.select-parent {
  position: relative;
  display: block;
  max-width: 600px;
  width: 100%;
  color: c(dark, 0.8);
  cursor: pointer;
  > span {
    position: absolute;
    left: 14px;
    font-size: 12px;
    top: 8px;
    pointer-events: none;
    transition: all 250ms ease-in-out;
    color: c(dark, 0.7);
  }
  .add-form & {
    max-width: 600px;
  }
  > i {
    font-size: 13px;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    position: absolute;
    pointer-events: none;
  }
}

@media screen and (max-width: 580px) {
  button,
  input[type='button'],
  input[type='submit'],
  a.button {
    width: 100%;
  }
}
