.loader {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.car {
  &__body {
    animation: shake 0.2s ease-in-out infinite alternate;
  }
  &__line {
    transform-origin: center;
    stroke-dasharray: 100;
    animation: line 4s ease-in-out infinite reverse;
    animation-fill-mode: forwards;
    &--top {
      animation-delay: 0s;
    }
    &--middle {
      animation-delay: 0.2s;
    }
    &--bottom {
      animation-delay: 0.4s;
    }
  }
}
@keyframes shake {
  0% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(3%);
  }
}
@keyframes line {
  to {
    stroke-dashoffset: 600;
  }
}
