@import '../../assets/scss/core';

header {
  background: c(light);
  box-shadow: 0 0 rem(8) var(--shadow);
  padding: rem(10) rem(15);
  z-index: 999;
  .header-block {
    max-width: var(--max-width);
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: rem(51);
      height: rem(60);
    }
    nav {
      display: flex;
      align-items: center;
      a {
        position: relative;
        &:not(:last-child) {
          margin: 0 20px 0 0;
          &:after {
            content: '';
            position: absolute;
            right: -13px;
            top: 7px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: c(dark, 0.2);
            display: inline-block;
            pointer-events: none;
          }
        }
      }
    }
  }
}
