@import '../../assets/scss/core';

main {
  overflow-y: scroll;
  .content {
    padding: rem(15);
    max-width: calc(var(--max-width) + #{rem(30)});
    margin: 0 auto;
    width: 100%;
  }
}
#root {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto 1fr auto;
  overflow: hidden;
}
.go-back {
  width: 100%;
  height: rem(40);
  font-size: rem(15);
  background: c(primary);
  display: flex;
  margin: 0 0 rem(30);
  justify-content: center;
  align-items: center;
  border-radius: rem(5);
  text-transform: uppercase;
  color: c(light);
  &:hover,
  &:active {
    color: c(light);
  }
}
.preorder {
  h1 {
    margin: rem(20) 0;
    text-align: center;
  }
  .input-block {
    max-width: rem(500);
    display: grid;
    gap: rem(10);
    width: 100%;
    margin: rem(20) auto 0;
    .buttons-block {
      display: inline-grid;
      margin: rem(10) auto 0 0;
      gap: rem(15);
      grid-auto-flow: column;
    }
  }
}
