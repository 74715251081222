@font-face {
  font-family: 'yan_icons';
  src:  url('fonts/yan_icons.eot?js3hfq');
  src:  url('fonts/yan_icons.eot?js3hfq#iefix') format('embedded-opentype'),
    url('fonts/yan_icons.ttf?js3hfq') format('truetype'),
    url('fonts/yan_icons.woff?js3hfq') format('woff'),
    url('fonts/yan_icons.svg?js3hfq#yan_icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'yan_icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-armenia:before {
  content: "\e935";
}
.icon-report:before {
  content: "\e932";
}
.icon-packages:before {
  content: "\e933";
}
.icon-invoice:before {
  content: "\e934";
}
.icon-telephone:before {
  content: "\e931";
}
.icon-done:before {
  content: "\e930";
}
.icon-import:before {
  content: "\e92e";
}
.icon-export:before {
  content: "\e92f";
}
.icon-warning-alert:before {
  content: "\e92d";
}
.icon-shipment:before {
  content: "\e926";
}
.icon-import-export:before {
  content: "\e929";
}
.icon-error-alert:before {
  content: "\e92a";
}
.icon-info-alert:before {
  content: "\e92b";
}
.icon-success-alert:before {
  content: "\e92c";
}
.icon-package:before {
  content: "\e927";
}
.icon-warehouse-2:before {
  content: "\e928";
}
.icon-scan:before {
  content: "\e924";
}
.icon-view:before {
  content: "\e925";
}
.icon-delivered:before {
  content: "\e922";
}
.icon-custom:before {
  content: "\e923";
}
.icon-area:before {
  content: "\e920";
}
.icon-region:before {
  content: "\e91f";
}
.icon-warehouse:before {
  content: "\e91d";
}
.icon-location:before {
  content: "\e911";
}
.icon-find-order:before {
  content: "\e91e";
}
.icon-new-order:before {
  content: "\1f310";
}
.icon-search-order:before {
  content: "\e91c";
}
.icon-xlsx:before {
  content: "\e915";
}
.icon-csv:before {
  content: "\e91b";
}
.icon-reports:before {
  content: "\e921";
}
.icon-settings:before {
  content: "\e91a";
}
.icon-edit:before {
  content: "\e90e";
}
.icon-trash:before {
  content: "\e917";
}
.icon-print:before {
  content: "\e918";
}
.icon-404:before {
  content: "\e919";
}
.icon-dark-theme:before {
  content: "\e916";
}
.icon-not-found:before {
  content: "\e914";
}
.icon-orders:before {
  content: "\e909";
}
.icon-dashboard:before {
  content: "\e908";
}
.icon-users:before {
  content: "\e907";
}
.icon-add-user:before {
  content: "\e913";
}
.icon-postman:before {
  content: "\e912";
}
.icon-sidebar:before {
  content: "\e905";
}
.icon-user:before {
  content: "\e90c";
}
.icon-logout:before {
  content: "\e90d";
}
.icon-kg:before {
  content: "\e90b";
}
.icon-euro:before {
  content: "\e90a";
}
.icon-prew:before {
  content: "\e936";
}
.icon-next:before {
  content: "\e937";
}
.icon-accept:before {
  content: "\e900";
}
.icon-arrow_down:before {
  content: "\e901";
}
.icon-arrow_left:before {
  content: "\e902";
}
.icon-arrow_right:before {
  content: "\e903";
}
.icon-arrow_up:before {
  content: "\e904";
}
.icon-cancel:before {
  content: "\e906";
}
.icon-minus:before {
  content: "\e90f";
}
.icon-more:before {
  content: "\e910";
}
