@import '../../assets/scss/core';

footer {
  padding: 8px 15px;
  box-shadow: 0 0 8px var(--shadow);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: c(dark, 0.7);
  font-size: 14px;
  width: 100%;
  z-index: 999;
  position: relative;
  background: c(light);
  .content {
    max-width: var(--max-width);
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .right-side {
    display: inline-grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    gap: 10px;
    align-items: center;
    .dot-separator {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: c(dark, 0.4);
    }
    .dark-mode-toggle {
      border-radius: 20px;
      background: c(dark, 0.2);
      width: 40px;
      height: 24px;
      cursor: pointer;
      padding: 2px;
      transition: all 300ms ease-in-out;
      > i {
        background: c(light);
        font-size: 12px;
        width: 20px;
        height: 20px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: c(dark);
        transition: all 300ms ease-in-out;
      }
    }
  }
  .language {
    position: relative;
    cursor: pointer;
    .flag {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border-radius: 50%;
      &.ru {
        background-image: url('../../assets/images/flags/rus.svg');
      }
      &.de {
        background-image: url('../../assets/images/flags/deu.svg');
      }
      &.am {
        background-image: url('../../assets/images/flags/arm.svg');
      }
      &.en {
        background-image: url('../../assets/images/flags/usa.svg');
      }
    }
    &:hover,
    &:active {
      .language-block {
        opacity: 1;
        visibility: visible;
        bottom: 20px;
      }
    }
    .current {
      color: c(primary);
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      gap: 5px;
      .flag {
        width: 21px;
        height: 21px;
      }
    }
    .language-block {
      position: absolute;
      bottom: rem(15);
      right: 0;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      transition: all 200ms ease-in-out;
      .language-list {
        padding: 5px;
        background: c(light);
        border: 1px solid c(dark, 0.1);
        border-radius: 5px;
        margin: 0 0 rem(5);
        text-align: right;
        font-size: 14px;
        li {
          display: inline-grid;
          grid-template-columns: 1fr auto;
          align-items: center;
          gap: 5px;
          padding: 5px;
          width: 100%;
          color: c(dark, 0.8);
          border-radius: 5px;
          &:active {
            background: c(dark, 0.03);
            color: c(dark);
          }
          @include only-desktop() {
            &:hover {
              background: c(dark, 0.03);
              color: c(dark);
            }
          }
          .flag {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
