* {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0 !important;
  border-radius: 0;
  background: none;
  vertical-align: top;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: transparent;
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}
a {
  color: c(dark, 0.8);
  text-decoration: none;
  &:hover,
  &:active {
    color: c(primary);
    text-decoration: none;
  }
}
a,
button,
input {
  transition: 200ms color, 200ms background, 200ms border;
}
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
img,
svg {
  display: block;
  max-width: 100%;
}
button,
input,
optgroup,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  font: inherit;
  appearance: none;
  display: block;
  color: inherit;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
  &::-ms-clear {
    display: none;
  }
  @include placeholder {
    opacity: 1;
    font-family: inherit;
  }
}
h1 {
  font-size: rem(40);
  line-height: 1.4;
}
h2 {
  font-size: rem(34);
}
h3 {
  font-size: rem(28);
}
h4,
h5 {
  font-size: rem(22);
}
h6 {
  font-size: rem(18);
}
h1,
h2,
h3,
h4 {
  font-weight: bold;
}
li {
  display: block;
}
.ellipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
.pointer-events-none {
  pointer-events: none;
}
.cursor-pointer {
  cursor: pointer;
}
::selection {
  background: c(primary, 0.4);
}
