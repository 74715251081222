@import '../../assets/scss/core';

.error-boundary-h {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  grid-area: main;
  padding: 30px;
  .error-box {
    text-align: center;
  }
  .err-text-v {
    margin: 40px 0;
    font-size: 18px;
  }
  .logo-container {
    color: c(primary);
    font-size: 100px;
    text-decoration: none;
    font-weight: bold;
  }
}
